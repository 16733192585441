import React, { useContext, useMemo, useState } from "react";
import { Box, Stack, useColorModeValue, Text, Flex, useBreakpointValue } from "@chakra-ui/react";
import { useTileProps, useCollectionKey, useEntitlementKey } from "hooks";
import { SimpleMetric } from "../dashboard/SimpleMetric";
import { ComingSoonOverlay } from "screens/common/components/ComingSoonOverlay";
import QuadrantChart from "./chartComponents/QuadrantChart";
import { useParams } from "react-router-dom";
import { CollectionsFilterContext } from "./CollectionsFilterContext";
import { useCollectionsStocksContext } from "./CollectionsStocksProvider";
import { StockMarketChart } from "screens/landing/tabs/collections/chartComponents/StockMarketChart";
import type { StockMarketChartData } from "screens/landing/tabs/collections/chartComponents/StockMarketChart/StockMarketChart";
import type { ReturnOnEquityChartData } from "./chartComponents/ReturnOnEquityChart/ReturnOnEquityChart";
import ReturnOnEquityChart from "./chartComponents/ReturnOnEquityChart/ReturnOnEquityChart";
import { BuySellHoldChart } from "./chartComponents/BuySellHoldChart/BuySellHoldChart";
import { useSwipeable } from "react-swipeable";
import groupBy from "lodash/groupBy";

// TODO replace all ticker and exchange to use useCompanyStockFromCollectionContentsOrConversation for old projects

export const ProjectMetrics = () => {
  const { projectFilter } = useParams();
  const collectionMetadataIds = useCollectionKey(projectFilter, "metadataIds");
  const { filteredCollections } = useContext(CollectionsFilterContext);
  const commonTileProps = useTileProps();
  const { getStockData } = useCollectionsStocksContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isWideScreen = useBreakpointValue({ base: false, md: false, lg: false, xl: true }, { fallback: "md", ssr: false });
  const TILEWIDTH = isMobile ? 25 : 33;
  const showProjectMetrics = useEntitlementKey("ui_project_metrics");

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSlide("next"),
    onSwipedRight: () => handleSlide("prev"),
    trackMouse: true,
  });

  const handleSlide = (direction: "prev" | "next") => {
    if (direction === "prev") {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, 3));
    }
  };

  const groupedCollectionsIds: string[] = useMemo(() => {
    const grouped = groupBy(filteredCollections, (collection) => collection.projectGroupId || collection.id);

    return Object.keys(grouped);
  }, [filteredCollections]);

  const labelsForQuartersPastTwoYears = useMemo(() => {
    const quarters: string[] = [];
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentQuarter = Math.floor(today.getMonth() / 3) + 1;

    for (let i = 0; i < 8; i++) {
      let year = currentYear - Math.floor((i + (4 - currentQuarter)) / 4);
      let quarter = currentQuarter - (i % 4);
      if (quarter <= 0) {
        quarter += 4;
        year--;
      }
      quarters.unshift(`Q${quarter} ${year}`);
    }

    return quarters;
  }, []);

  const generateROEData = useMemo(() => {
    return (ids: string[] | undefined): ReturnOnEquityChartData[] => {
      return labelsForQuartersPastTwoYears.map((quarter) => {
        const data: {
          [key: string]: { value: number; projectId: string };
        } = {};
        ids?.forEach((id) => {
          const collection = filteredCollections?.find((collection) => collection.id === id);
          if (collection?.ticker && collection.stockExchange) {
            const stockData = getStockData(collection.ticker, collection.stockExchange); // TODO replace all ticker and exchange to use useCompanyStockFromCollectionContentsOrConversation for old projects
            data[collection.ticker] = {
              value: Number(stockData?.ticker_quote?.price ?? 0),
              projectId: id,
            };
          }
        });
        return {
          quarter: quarter,
          ...data,
        };
      });
    };
  }, [filteredCollections, labelsForQuartersPastTwoYears, getStockData]);

  const ROEData = useMemo(() => {
    return generateROEData(collectionMetadataIds || groupedCollectionsIds);
  }, [collectionMetadataIds, groupedCollectionsIds, generateROEData]);

  const generateTradingValueData = useMemo(() => {
    return (ids: string[] | undefined): StockMarketChartData[] => {
      return (
        ids
          ?.map((id) => {
            const collection = filteredCollections?.find((collection) => collection.id === id);
            if (collection?.ticker && collection?.stockExchange) {
              const stockData = getStockData(collection.ticker, collection.stockExchange);
              const price = Number(stockData?.ticker_quote?.price ?? 0);
              return {
                projectId: id,
                ticker: collection.ticker,
                currentPrice: price,
                fairValueLow: price * 0.8, // TODO: Replace with actual fairValueLow data
                fairValueHigh: price * 1.15, // TODO: Replace with actual fairValueHigh data
              };
            }
            return null;
          })
          .filter((item): item is NonNullable<typeof item> => item !== null) ?? []
      );
    };
  }, [filteredCollections, getStockData]);

  const tradingValueData = useMemo(() => {
    return generateTradingValueData(collectionMetadataIds || groupedCollectionsIds);
  }, [collectionMetadataIds, groupedCollectionsIds, generateTradingValueData]);

  const generateQuadrantData = useMemo(() => {
    return (ids: string[] | undefined) => {
      return (
        ids
          ?.map((id) => {
            const collection = filteredCollections?.find((collection) => collection.id === id);
            const x = collection?.investmentScoreAnalytical?.value ?? -1;
            const y = collection?.investmentScoreSentiment?.value ?? -1;
            if (x === -1 || y === -1) return null;

            return {
              projectId: id,
              explanations: {
                analytical: collection?.investmentScoreAnalytical?.explanation,
                sentiment: collection?.investmentScoreSentiment?.explanation,
              },
              x: Math.min(Math.max(Number(x), -1), 100),
              y: Math.min(Math.max(Number(y), -1), 100),
              color: "gray.500",
            };
          })
          .filter((item): item is NonNullable<typeof item> => item !== null) ?? []
      );
    };
  }, [filteredCollections]);

  const quadrantData = useMemo(() => {
    return generateQuadrantData(collectionMetadataIds || groupedCollectionsIds);
  }, [collectionMetadataIds, groupedCollectionsIds, generateQuadrantData]);

  const bgColor = useColorModeValue("gray.200", "gray.800");

  const arrowStyles = {
    cursor: "pointer",
    pos: "absolute",
    top: "50%",
    w: "auto",
    mt: "-22px",
    mx: "-12px",
    p: "1px 12px",
    bg: "white",
    color: "gray.700",
    fontWeight: "bold",
    fontSize: "22px",
    transition: "0.6s ease",
    borderRadius: "5px",
    userSelect: "none",
    _hover: {
      bg: "gray.100",
    },
  } as const;

  return (
    <Box position="relative" height="100%" p="1rem" bgColor={bgColor} mb="1rem" maxWidth="100%" mx="auto">
      <Flex w="full" alignItems="center" justifyContent="center" position="relative">
        <Flex w="full" overflow="hidden" {...handlers}>
          <Stack
            direction="row"
            spacing={"1rem"}
            style={{
              transform: `translateX(calc(-${currentIndex} * (${TILEWIDTH}rem + 1rem)))`,
              transition: "transform 0.3s ease-in-out",
              width: "110%",
            }}>
            {!showProjectMetrics && (
              <ComingSoonOverlay message="Upgrade to a Business Plan for Project Metrics." height="100%" width="100%" />
            )}
            <Box {...commonTileProps} width={`${TILEWIDTH}rem`} flexShrink={0}>
              <SimpleMetric title="Portfolio Investment (Pi) Quadrant">
                <QuadrantChart data={quadrantData} xAxisLabel="Strength of Fundamentals" yAxisLabel="Strength of Market Perception" />
              </SimpleMetric>
            </Box>
            {tradingValueData && (
              <Box {...commonTileProps} width={`${TILEWIDTH}rem`} flexShrink={0}>
                <SimpleMetric title="Fair Market Value vs Trading Price">
                  <StockMarketChart data={tradingValueData} />
                </SimpleMetric>
              </Box>
            )}
            {ROEData && (
              <Box {...commonTileProps} width={`${TILEWIDTH}rem`} flexShrink={0}>
                <SimpleMetric title="Return on Equity">
                  <ReturnOnEquityChart data={ROEData} />
                </SimpleMetric>
              </Box>
            )}
            <Box {...commonTileProps} width={`${isMobile ? 25 : 23}rem`} flexShrink={0}>
              <SimpleMetric title="Buy Sell and Hold Analysis">
                <BuySellHoldChart />
              </SimpleMetric>
            </Box>
          </Stack>
          {currentIndex > 0 && showProjectMetrics && (
            <Text {...arrowStyles} left="0" onClick={() => handleSlide("prev")}>
              &#10094;
            </Text>
          )}
          {currentIndex < (isMobile ? 3 : 2) && !isWideScreen && showProjectMetrics && (
            <Text {...arrowStyles} right="0" onClick={() => handleSlide("next")}>
              &#10095;
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
